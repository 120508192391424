import { Field } from 'Util/Query';

/** @namespace Pwa/Query/CmsPreview/Query/CmsPreviewQuery */
export class CmsPreviewQuery {
    getQuery({ identifier }) {
        if (!identifier) {
            throw new Error('Missing argument `identifier`!');
        }

        const CmsPreview = new Field('cmsPreview').addFieldList(this._getPageFields());

        if (identifier) {
            CmsPreview.addArgument('identifier', 'String!', identifier);
        }

        return CmsPreview;
    }

    _getPageFields() {
        return ['title', 'content', 'content_heading', 'meta_title', 'meta_description', 'meta_keywords'];
    }
}

export default new CmsPreviewQuery();
