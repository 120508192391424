import { connect } from 'react-redux';

import InstallPrompt from 'Component/InstallPrompt';
import CmsPage from 'Route/CmsPage';
import {
    HomePageContainer as SourceHomePageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/HomePage/HomePage.container';
import { updateConfigData } from 'Store/TradeDoublerConfig/TradeDoublerConfig.action';
import { isCrawler, isSSR } from 'Util/Browser/Browser';

import './HomePage.style';

/** @namespace Pwa/Route/HomePage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    updateConfigData: (configData) => dispatch(updateConfigData(configData)),
});

export { mapStateToProps };
/** @namespace Pwa/Route/HomePage/Container/HomePageContainer */
export class HomePageContainer extends SourceHomePageContainer {
    componentDidMount() {
        super.componentDidMount();
        if (window.TDConf?.Config) {
            this.setTDConfigObject();
        }
    }

    componentDidUpdate() {
        if (window.TDConf?.Config) {
            this.setTDConfigObject();
        }
    }

    containerProps() {
        return {
            ...super.containerProps(),
            minifyForCrawlers: true,
        };
    }

    setTDConfigObject() {
        const { updateConfigData } = this.props;
        const configData = {
            products: [],
            orderId: '',
            orderValue: '',
            pageType: 'homepage',
        };

        updateConfigData({ configData });
    }

    render() {
        return (
            <div block="HomePage" mods={{ isPrerendered: isSSR() || isCrawler() }}>
                <InstallPrompt />
                <CmsPage {...this.containerProps()} />
            </div>
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HomePageContainer);
