import { LOADING_TIME } from '@scandipwa/scandipwa/src/route/CmsPage/CmsPage.config';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { CMS_PAGE } from 'Component/Header/Header.config';
import CmsPageQuery from 'Query/CmsPage.query';
import CmsPreviewQuery from 'Query/CmsPreview.query';
import {
    CmsPageContainer as SourceCmsPageContainer,
    mapDispatchToProps as sourceMapDispatchToProps,
    mapStateToProps,
} from 'SourceRoute/CmsPage/CmsPage.container';
import { setHeadingBanner } from 'Store/Layout/Layout.action';
import { debounce } from 'Util/Request';
import { getQueryParam, isHomePageUrl } from 'Util/Url';

import CmsPage from './CmsPage.component';

import './CmsPage.override.style.scss';

/** @namespace Pwa/Route/CmsPage/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    ...sourceMapDispatchToProps(dispatch),
    setHeadingBanner: (...args) => dispatch(setHeadingBanner(...args)),
});

export { mapStateToProps };

/** @namespace Pwa/Route/CmsPage/Container/CmsPageContainer */
export class CmsPageContainer extends SourceCmsPageContainer {
    static propTypes = {
        ...SourceCmsPageContainer.propTypes,
        isPreview: PropTypes.bool,
    };

    static defaultProps = {
        ...SourceCmsPageContainer.defaultProps,
        isPreview: false,
    };

    componentWillUnmount() {
        super.componentWillUnmount();
        const { setHeadingBanner } = this.props;

        setHeadingBanner(null);
    }

    containerProps() {
        const { minifyForCrawlers } = this.props;

        return {
            ...super.containerProps(),
            minifyForCrawlers,
        };
    }

    onPageLoad({ cmsPage: page }) {
        const {
            location: { pathname },
            updateMeta,
            setHeaderState,
            updateBreadcrumbs,
        } = this.props;

        const cleanPage = this.stripBanner(page);
        const { content_heading, meta_title, title, meta_description, meta_keywords } = cleanPage;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(cleanPage);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        if (!isHomePageUrl(pathname)) {
            setHeaderState({
                name: CMS_PAGE,
                title: content_heading,
                onBackClick: () => history.goBack(),
            });
        }

        this.setState({ page: cleanPage, isLoading: false, isPageLoaded: true });
    }

    onPreviewLoad({ cmsPreview: page }) {
        const { updateMeta, updateBreadcrumbs } = this.props;

        const { meta_title, title, meta_description, meta_keywords } = page;

        debounce(this.setOfflineNoticeSize, LOADING_TIME)();

        updateBreadcrumbs(page);
        updateMeta({
            title: meta_title || title,
            description: meta_description,
            keywords: meta_keywords,
            canonical_url: window.location.href,
        });

        this.setState({ page, isLoading: false, isPageLoaded: true });
    }

    getPreviewIdentifier() {
        const { location } = this.props;

        const identifier = getQueryParam('identifier', location);

        return identifier;
    }

    requestPage() {
        const { isPreview } = this.props;
        const params = this.getRequestQueryParams();
        const previewId = this.getPreviewIdentifier();

        const { id, identifier } = params;

        if (isPreview && !previewId) {
            return;
        }

        if (isPreview && previewId) {
            this.setState({ isLoading: true });
            this.fetchData([CmsPreviewQuery.getQuery({ identifier: previewId })], this.onPreviewLoad.bind(this), () =>
                this.setState({ isLoading: false })
            );

            return;
        }

        if (!id && !identifier) {
            return;
        }

        this.setState({ isLoading: true });

        this.fetchData([CmsPageQuery.getQuery(params)], this.onPageLoad.bind(this), () =>
            this.setState({ isLoading: false })
        );
    }

    stripBanner(page) {
        const { setHeadingBanner } = this.props;

        // https://regex101.com/r/YX9uUZ/1
        const startingTag = '<div id="heading-banner">';
        // eslint-disable-next-line
        const endingTag = '</div>';
        const bannerRegexp = new RegExp(`(${startingTag})(?:(?!(${startingTag})).)*?(${endingTag})`, 'gms');

        // below is used to parse from content edited in wysiwyg - it containes different encoding unfortunately
        // don't blame me, magento's fault
        const startingTagAfterEdit = '&lt;div id="heading-banner"&gt;';
        // eslint-disable-next-line
        const endingTagAfterEdit = '&lt;\/div&gt;';
        const bannerRegexpAfterEdit = new RegExp(
            `(${startingTagAfterEdit})(?:(?!(${startingTagAfterEdit})).)*?(${endingTagAfterEdit})`,
            'gms'
        );

        const banner = page.content.match(bannerRegexp) || page.content.match(bannerRegexpAfterEdit);

        if (banner) {
            setHeadingBanner(banner[0]);
        }

        return {
            ...page,
            content: page.content.replaceAll(bannerRegexp, '').replaceAll(bannerRegexpAfterEdit, ''),
        };
    }

    render() {
        return <CmsPage {...this.containerProps()} />;
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CmsPageContainer);
