import Html from 'Component/Html';
import TextPlaceholder from 'Component/TextPlaceholder';
import { CmsPage as SourceCmsPage } from 'SourceRoute/CmsPage/CmsPage.component';
import { isHomePageUrl } from 'Util/Url';

import './CmsPage.style';

/** @namespace Pwa/Route/CmsPage/Component/CmsPage */
export class CmsPage extends SourceCmsPage {
    renderHomepagePlaceholder() {
        return (
            <>
                <div block="Slider" mods={{ placeholder: true }}>
                    <TextPlaceholder length="full" />
                </div>
                <div block="ProductListWithoutHotspotsWidget" mods={{ placeholder: true }}>
                    <TextPlaceholder length="full" />
                </div>
                <div className="ProductList ProductListWidget ProductListWidget_extended">
                    <div block="ProductListWidget_extended_placeholder">
                        <TextPlaceholder length="full" />
                    </div>
                </div>
                <div block="CmsPage" elem="SectionPlaceholder" />
                <div block="CmsPage" elem="SectionPlaceholder" />
                <div block="CmsPage" elem="SectionPlaceholder" />
            </>
        );
    }

    renderCmsPlaceholder() {
        return (
            <>
                <div block="CmsPage" elem="SectionPlaceholder" />
                <div block="CmsPage" elem="SectionPlaceholder" />
                <div block="CmsPage" elem="SectionPlaceholder" />
            </>
        );
    }

    renderContent() {
        const {
            isLoading,
            minifyForCrawlers,
            page: { content },
        } = this.props;

        if (isLoading) {
            if (isHomePageUrl(window.location.pathname)) {
                return this.renderHomepagePlaceholder();
            }

            return this.renderCmsPlaceholder();
        }

        if (!isLoading && !content) {
            return null;
        }

        return <Html content={content} minifyForCrawlers={minifyForCrawlers} />;
    }
}

export default CmsPage;
