import { connect } from 'react-redux';
import { withRouter } from 'react-router';

import { SUBCATEGORY } from 'Component/Header/Header.config';
import Menu from 'Component/Menu';
import {
    mapDispatchToProps,
    mapStateToProps as sourceMapStateToProps,
    MenuPageContainer as SourceMenuPageContainer,
} from 'SourceRoute/MenuPage/MenuPage.container';
import { TOP_NAVIGATION_TYPE } from 'Store/Navigation/Navigation.reducer';

/** @namespace Pwa/Route/MenuPage/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    ...sourceMapStateToProps(state),
    compareTotals: state.ProductCompareReducer.count,
    navigationState: state.NavigationReducer[TOP_NAVIGATION_TYPE].navigationState,
});

/** @namespace Pwa/Route/MenuPage/Container/MenuPageContainer */
export class MenuPageContainer extends SourceMenuPageContainer {
    render() {
        const {
            compareTotals,
            navigationState: { name },
        } = this.props;

        return (
            <main block="MenuPage" mods={{ isCompare: compareTotals > 0, isSubcategoryOpen: name === SUBCATEGORY }}>
                <Menu />
            </main>
        );
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(MenuPageContainer));
